<template>
   <div>
      <page-header v-if="hasCreatePermission" btnNew="ACTIONS.NEW" pageTitle="RESERVE_TYPES.INDEX_TITLE"
                   @newType="setRegisterModal"/>
      <page-header v-if="!hasCreatePermission" pageTitle="RESERVE_TYPES.INDEX_TITLE"/>

      <list-component :items="items.data" :haveOptions="false" v-if="items && items.data">
         <template v-slot:principal-info="{items}">
            <div class="row gutters" style="align-items: center;">
               <div class="col-xl-2 col-lg-3 col-md-3 col-sm-5 col-12 text-center">
                  <div class="task-name list-primary" :style="{color: items.iten.text_color}">{{ items.iten.name }}
                  </div>
               </div>
               <div class="col-xl-3 col-lg-3 col-md-2 col-sm-7 col-12" :style="widthPhone() ? 'margin-left: 5%' : ''"
                    v-if="hasShowPermission">
                  <div class="custom-control custom-switch">
                     <input type="checkbox" class="custom-control-input" :id="'active'+items.key"
                            v-model="items.iten.has_limit_time"
                            @click="updateTimeLimit(items.iten, items.key)" :disabled="haveLimitTime(items.iten)">
                     <label class="custom-control-label" :for="'active'+items.key">{{
                           t('RESERVE_TYPES.HAS_TIME_LIMIT')
                        }}</label>
                  </div>
               </div>
               <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-11" v-if="hasShowPermission" :style="inputsStyle()">
                  <label for="front_size">{{ t('PROPERTIES.DESCRIPTION') }}: </label>
                  <input type="text" class="form-control" v-model="items.iten.name" :disabled="!hasUpdatePermission"
                         @focus="setOriginalReserveType(items.iten.name)"
                         @change="updateReserveType(items.iten.name, items.iten, items.key,'PROPERTIES.DESCRIPTION')">
               </div>
               <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-11" v-if="hasShowPermission" :style="inputsStyle()">
                  <label for="front_size">{{ t('SITUATIONS.TEXT_COLOR') }}: </label>
                  <input type="color" id="colorpicker" class="form-control" v-model="items.iten.text_color"
                         :disabled="!hasUpdatePermission"
                         @focus="setOriginalReserveType(items.iten.text_color)"
                         @change="updateReserveType(items.iten.text_color, items.iten, items.key,'SITUATIONS.TEXT_COLOR')">
               </div>
               <div v-if="hasDeletePermission && notHaveReserves" id="deleteButton"
                    class="col-xl-2 col-lg-1 col-md-1 col-sm-2 col-11 text-right" :style="buttonStyle()">
                  <button class="btn btn-secondary" :title="t('ACTIONS.DELETE')" @click="showAlert(items.iten.id)">
                     <i class="icon-delete"></i>
                  </button>
               </div>
            </div>
         </template>
      </list-component>

      <div class="card" v-if="items && !items.data">
         <div class="card-header"></div>
         <div class="card-body pt-0">
            <div class="text-center">
               <h5>{{ t('GENERAL.MSG.NO_REGISTER', {name: t('RESERVE_TYPES.INDEX_TITLE'), os: 'as'}) }}</h5>
            </div>
         </div>
      </div>

      <pagination-component v-if="items && items.data" :items="items" :to="'/tipo-reserva'"
                            @changePage="index($event)"></pagination-component>

      <modal :titleModal="modalTitle">
         <template v-slot:title>{{ t(modalTitle) }}</template>
         <create-reserve-types v-if="reserveType" :reserveTypeIndex="reserveType" :showLimitTime="showLimitTime"
                               @created="updateAfterCreated"></create-reserve-types>
      </modal>
   </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import Modal from "@/components/modal";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import ReserveTypes from '@/services/ReserveTypes';
import Permission from '@/components/permission.vue';
import pageHeader from "@/components/layouts/pageHeader";
import createReserveTypes from "./createReserveTypesComponent";
import ListComponent from "@/components/layouts/listComponent.vue";
import PaginationComponent from "@/components/layouts/PaginationComponent";

export default {
   name: 'IndexReserveTypes',
   mixins: [Permission, Validate],
   components: {
      Modal,
      pageHeader,
      ListComponent,
      createReserveTypes,
      PaginationComponent,
   },

   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },

   data() {
      return {
         notHaveReserves: true,
         items: null,
         modalTitle: null,
         reserveTypes: [],
         reserveType: null,
         showLimitTime: null,
         hasShowPermission: false,
         originalReserveType: null,
         hasCreatePermission: false,
         hasUpdatePermission: false,
         hasDeletePermission: false,
      }
   },

   mounted() {
      this.$store.commit('changeLoading', true);
      this.notHaveReserves = true;
      this.index();
      this.startPermission();
   },

   methods: {
      async index(page = 1) {
         await ReserveTypes.index(page).then(resp => {
            this.items = resp.data;
            this.checkIfAreReserveNow(this.items);
            this.reserveTypes = resp.data.data;
         }).catch(error => {
            this.$store.commit('changeLoading', false);
            this.errorMsg(error)
         });
         this.showLimitTime = this.items.data && this.items.data.find(t => t.has_limit_time) ? false : true;

         this.$store.commit('changeLoading', false);
      },

      startPermission() {
         this.hasShowPermission = this.validatePermissions('ReserveType:show');
         this.hasCreatePermission = this.validatePermissions('ReserveType:create');
         this.hasUpdatePermission = this.validatePermissions('ReserveType:update');
         this.hasDeletePermission = this.validatePermissions('ReserveType:delete');
      },

      checkIfAreReserveNow() {
         ReserveTypes.haveReserveNow({type: 0}).then((res) => {
            if (!res.data.validations) {
               this.notHaveReserves = false;
            }
         });
      },

      setRegisterModal() {
         this.modalTitle = 'RESERVE_TYPES.MODAL_TITLE.ADD';

         this.reserveType = {
            name: null,
            text_color: '#000000',
            has_limit_time: false
         }
      },

      showAlert(reserve_id) {
         this.$swal.fire({
            title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),

            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: `Excluir`,
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.commit('changeLoading', true);

               ReserveTypes.delete(reserve_id).then(resp => {
                  document.getElementById('closeX').click();
                  this.index();
                  this.$store.commit('changeLoading', false);
                  this.toast.success(resp.data.validations.message);

               }).catch(error => {
                  this.$store.commit('changeLoading', false);
                  this.errorMsg(error)
               });
            }
         });
      },

      updateAfterCreated() {
         this.index();
      },

      updatePaginate(page) {
         this.updateItemsFromPaginate(page, ReserveTypes)
      },


      setOriginalReserveType(reserveType) {
         this.originalReserveType = reserveType;
      },

      updateReserveType(target, reserveType, key, msg) {
         if (target != this.originalReserveType) {
            ReserveTypes.update(reserveType).then(resp => {
               this.items.data[key] = resp.data;
               this.toast.success(this.t('GENERAL.MSG.PLACEHOLDER_UPDATE_SUCCESS', {name: this.t(msg)}));
            }).catch(error => {
               this.errorMsg(error);
            });
         }
      },

      updateTimeLimit(reserveType, key) {
         reserveType.has_limit_time = !reserveType.has_limit_time;
         ReserveTypes.update(reserveType).then(resp => {
            this.items.data[key] = resp.data;
            this.toast.success(this.t('GENERAL.MSG.PLACEHOLDER_UPDATE_SUCCESS', {name: this.t('RESERVE_TYPES.HAS_TIME_LIMIT')}));
         }).catch(error => {
            this.errorMsg(error);
         });
      },

      haveLimitTime(reserveType) {
         let reseveWithLimiteTime = this.items.data.find(t => t.has_limit_time)

         if (reseveWithLimiteTime) {
            if (reseveWithLimiteTime.id == reserveType.id) {
               return false;
            } else {
               return true;
            }
         } else {
            return false;
         }
      },

      inputsStyle() {
         if (window.innerWidth < 768) {
            return 'margin-left: 5%; margin-top: 2%; margin-bottom: 2%'
         }
      },

      buttonStyle() {
         if (window.innerWidth < 768) {
            return 'margin-top: 4%; margin-right: 2%';
         } else {
            return 'margin-top: 2%; margin-bottom: 1%';
         }
      }
   }
}
</script>

<style>
.padding {
   padding: 3rem;
}

.border-top2 {
   border-top: 1px solid #e6ebf1;
}

.custom-control-input:disabled ~ .custom-control-label::before {
   top: 2px;
}

.custom-switch .custom-control-label::before {
   top: 2px;
}
</style>