import {http} from './config';

export default {
    create:(type) => {
        return http.post('api/v1/responsavelPorTarefa', type);
    },
    index:(page=1 ,companyId) => {
        return http.get('api/v1/responsavelPorTarefa?page=' + page, {headers: {'company-id': companyId}});
    },
    list:(companyId) => {
        return http.get('api/v1/responsavelPorTarefa/listar', {headers: {'company-id': companyId}})
    },
    update:(type,allotmentId) => {
        type.allotment_id = allotmentId;
        return http.put('api/v1/responsavelPorTarefa/' + type.id, type);
    },
    delete: (id) => {
        return http.delete('api/v1/responsavelPorTarefa/' + id);
    }
}
