<template>
   <div>
      <form @submit.prevent="create">
         <div class="modal-body">
            <div class="row gutters mt-2">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-1" v-if="showLimitTime">
                  <div class="custom-control custom-switch">
                     <input type="checkbox" class="custom-control-input" id="active"
                            v-model="reserveType.has_limit_time">
                     <label class="custom-control-label" for="active">{{ t('RESERVE_TYPES.HAS_TIME_LIMIT') }}</label>
                  </div>
               </div>
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="form-group">
                     <label for="front_size" class="required">{{ t('PROPERTIES.DESCRIPTION') }}: </label>
                     <input type="text" class="form-control" id="name" name="name"
                            :style="{color: reserveType.text_color}"
                            v-model="reserveType.name" @keyup="inputGeneric(reserveType.name, 'name')">
                     <div class="validation" id="invalidname" style="display: none">{{
                           t('GENERAL.MSG.REQUIRED_FIELD')
                        }}
                     </div>
                  </div>
               </div>
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="form-group">
                     <label for="front_size">{{ t('SITUATIONS.TEXT_COLOR') }}: </label>
                     <input type="color" id="colorpicker" class="form-control" v-model="reserveType.text_color">
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer2">
            <div class="justify-content-left">
               <h5 class="ml-2" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
            </div>
            <div class="ml-custom">
               <button class="btn btn-secondary" data-dismiss="modal" type="button">{{ t('ACTIONS.CLOSE') }}</button>
               <button class="btn btn-primary ml-3" type="submit">{{ t('ACTIONS.SAVE') }}</button>
            </div>
         </div>
      </form>
   </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import ReserveTypes from '@/services/ReserveTypes';

export default {
   name: 'createReserveTypes',
   props: ['reserveTypeIndex', 'showLimitTime'],
   mixins: [Validate],

   setup() {
      const {t} = useI18n();
      const toast = useToast();
      return {t, toast}
   },

   data() {
      return {
         invalid: [],
         reserveType: {},
      }
   },

   mounted() {
      this.reserveType = this.reserveTypeIndex;
   },

   watch: {
      reserveTypeIndex: function () {
         this.reserveType = this.reserveTypeIndex;
         document.getElementById('name').classList.remove('is-valid');
         document.getElementById('name').classList.remove('is-invalid');
      }
   },

   methods: {
      create() {
         this.invalid = [];

         if (this.checkForm()) {
            this.$store.commit('changeLoading', true);

            ReserveTypes.create(this.reserveType).then(() => {
               this.sucess();
            }).catch(error => {
               this.errorMsg(error);
               this.$store.commit('changeLoading', false);
            });
         }
      },

      async sucess() {
         document.getElementById('closeX').click();
         this.$store.commit('changeLoading', false);
         this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));
         this.$emit('created');
      },

      checkForm() {
         this.invalid = [];

         this.validateGeneric(this.reserveType.name, 'name');

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }
         return true;
      },
   }
}
</script>

<style>
.custom-control.custom-switch .custom-control-label::after {
   top: 4px !important;
}
</style>